<template>
  <div class="mb-2">
    <div class="d-flex">
      <div class="mr-3">
        <div class="form-group mb-1">
          <label class="sr-only" :for="'task-name-' + value.id">
            Name
          </label>
          <input
            :id="'task-name-' + value.id"
            class="form-control"
            type="text"
            placeholder="Name"
            :value="value.name"
            @input="updateValue($event.target.value, 'name')"
          />
        </div>
      </div>
      <div class="mr-3 flex-grow-1">
        <div class="form-group mb-1">
          <label class="sr-only" :for="'task-url-' + value.id">
            URL
          </label>
          <input
            class="form-control"
            :id="'task-url-' + value.id"
            type="text"
            placeholder="URL"
            :value="value.url"
            @input="updateValue($event.target.value, 'url')"
          />
        </div>
      </div>
      <div class="mr-3" style="max-width: 5em;" v-if="challenge_ladder">
        <div class="form-group mb-1">
          <label class="sr-only" :for="'task-score-' + value.id">
            Score
          </label>
          <input
            class="form-control"
            :id="'task-score-' + value.id"
            type="number"
            min="0"
            :value="value.score"
            @input="updateValue($event.target.value, 'score')"
          />
        </div>
      </div>
    </div>
    <div v-if="value.error" class="text-danger mb-3">
      Could not save task. Please check the URL.
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    challenge_ladder: Boolean,
  },
  methods: {
    updateValue(value, key) {
      this.value[key] = value;
      this.$emit("input", this.value);
    },
  },
};
</script>
