var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("router-link", {
    attrs: { to: _vm.to },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var href = ref.href
            var navigate = ref.navigate
            var isExactActive = ref.isExactActive
            return [
              _c(
                "li",
                { staticClass: "nav-item", class: [isExactActive && "active"] },
                [
                  _c(
                    "a",
                    {
                      staticClass: "nav-link",
                      attrs: { href: href },
                      on: { click: navigate }
                    },
                    [_vm._t("default")],
                    2
                  )
                ]
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }