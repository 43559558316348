<template>
  <div>
    <h1>Ranking</h1>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row font-weight-bold">
          <div class="col-3">
            Username
          </div>
          <div class="col-2">
            Codeforces
          </div>
          <div class="col-2">
            Spoj
          </div>
          <div class="col-2">
            Solved
          </div>
          <div class="col-2">
            Score
          </div>
        </div>
        <div v-for="user in users" :key="user.username">
          <div
            class="row cursor-pointer"
            data-toggle="collapse"
            :data-target="'#ladderinfo' + user.username"
            aria-expanded="false"
            :aria-controls="'ladderinfo' + user.username"
          >
            <div class="col-3">
              {{ user.username }}
            </div>
            <div class="col-2">
              <a
                :href="'https://codeforces.com/profile/' + user.codeforces"
                target="_blank"
              >
                {{ user.codeforces }}
              </a>
            </div>
            <div class="col-2">
              <a
                :href="'https://www.spoj.com/users/' + user.spoj + '/'"
                target="_blank"
              >
                {{ user.spoj }}
              </a>
            </div>
            <div class="col-2">
              {{ user.solved }}
            </div>
            <div class="col-2">
              {{ user.score }}
            </div>
          </div>
          <div class="collapse" :id="'ladderinfo' + user.username">
            <div v-for="ladder in user.ladders" class="row">
              <div class="col-7">
                <router-link :to="'/ladder/' + ladder.slug">
                  {{ ladder.name }}
                </router-link>
              </div>
              <div class="col-2">
                {{ ladder.solved }}
              </div>
              <div class="col-2">
                {{ ladder.score }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI } from "../fetch-utils";

export default {
  data() {
    return {
      users: [],
    };
  },
  created() {
    this.loadRanking();
  },
  methods: {
    loadRanking() {
      fetchAPI("/api/ladder/all_users")
        .then((res) => {
          this.users = res;
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
  },
};
</script>
