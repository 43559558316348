var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.infoLoaded
    ? _c("div", [
        _c("h1", [_vm._v(_vm._s(_vm.ladderInfo.name))]),
        _vm._v(" "),
        _c(
          "div",
          _vm._l(_vm.ladderInfo.description.split(/\r?\n\r?\n/), function(
            line
          ) {
            return _c("p", { staticClass: "mb-2" }, [
              _vm._v("\n      " + _vm._s(line) + "\n    ")
            ])
          }),
          0
        ),
        _vm._v(" "),
        _vm.ladderInfo.challenge_ladder
          ? _c("div", { staticClass: "text-muted" }, [
              _vm._v(
                "\n    Your score: " + _vm._s(_vm.ladderInfo.score) + "\n  "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _vm.ladderInfo.can_edit
              ? _c(
                  "router-link",
                  {
                    staticClass: "text-muted",
                    attrs: { to: "/ladder/" + _vm.ladderInfo.slug + "/edit" }
                  },
                  [_vm._v("\n      Edit Ladder\n    ")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-3" }, [
          _vm.ladderInfo.subscribed
            ? _c(
                "div",
                { staticClass: "col-12 col-md-6" },
                [
                  _c("h2", [
                    _vm._v(
                      "\n        Tasks: " +
                        _vm._s(_vm.ladderInfo.number_solved) +
                        "/" +
                        _vm._s(_vm.ladderInfo.total_tasks) +
                        "\n      "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm.ladderInfo.number_solved === 0
                      ? _c(
                          "a",
                          {
                            staticClass: "text-muted",
                            attrs: { href: "#" },
                            on: { click: _vm.leaveLadder }
                          },
                          [_vm._v("\n          Leave ladder\n        ")]
                        )
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.tasks, function(task) {
                    return _c(
                      "div",
                      { key: task.lt_id },
                      [
                        _c("task-entry", {
                          attrs: {
                            task: task,
                            challenge_ladder: _vm.ladderInfo.challenge_ladder
                          },
                          on: { solved: _vm.loadLadderInfo }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("h2", [
                  _vm._v("Tasks: " + _vm._s(_vm.ladderInfo.total_tasks))
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-success w-100",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.joinLadder($event)
                      }
                    }
                  },
                  [_vm._v("\n        Join\n      ")]
                )
              ]),
          _vm._v(" "),
          _vm.subscribers.length > 0
            ? _c("div", { staticClass: "col-12 col-md-6" }, [
                _c("h2", [_vm._v("Ranking")]),
                _vm._v(" "),
                _c("table", { staticClass: "table mt-3" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Rank")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Username")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Solved")]),
                      _vm._v(" "),
                      _vm.ladderInfo.challenge_ladder
                        ? _c("th", [_vm._v("Score")])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.subscribers, function(subscriber, idx) {
                      return _c("tr", { key: subscriber.username }, [
                        _c("td", [_vm._v(_vm._s(idx + 1))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(subscriber.username))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(subscriber.solved))]),
                        _vm._v(" "),
                        _vm.ladderInfo.challenge_ladder
                          ? _c("td", [_vm._v(_vm._s(subscriber.score))])
                          : _vm._e()
                      ])
                    }),
                    0
                  )
                ])
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }