function handleResponse(response) {
  return response.then((response) =>
    response.ok
      ? response.json().then((response) => {
          if (response.success) {
            return response.value;
          } else {
            return Promise.reject(response.message);
          }
        })
      : response.text().then((response) => Promise.reject(response))
  );
}

export function fetchAPI(url) {
  return handleResponse(fetch(url));
}

export function postAPI(url, data = {}) {
  return handleResponse(
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
  );
}
