<template>
  <div v-if="infoLoaded">
    <h1>{{ ladderInfo.name }}</h1>
    <div>
      <p
        v-for="line in ladderInfo.description.split(/\r?\n\r?\n/)"
        class="mb-2"
      >
        {{ line }}
      </p>
    </div>
    <div v-if="ladderInfo.challenge_ladder" class="text-muted">
      Your score: {{ ladderInfo.score }}
    </div>
    <div>
      <router-link
        v-if="ladderInfo.can_edit"
        class="text-muted"
        :to="`/ladder/${ladderInfo.slug}/edit`"
      >
        Edit Ladder
      </router-link>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-6" v-if="ladderInfo.subscribed">
        <h2>
          Tasks: {{ ladderInfo.number_solved }}/{{ ladderInfo.total_tasks }}
        </h2>
        <div>
          <a
            href="#"
            class="text-muted"
            v-if="ladderInfo.number_solved === 0"
            @click="leaveLadder"
          >
            Leave ladder
          </a>
        </div>
        <div v-for="task in tasks" :key="task.lt_id">
          <task-entry
            :task="task"
            :challenge_ladder="ladderInfo.challenge_ladder"
            @solved="loadLadderInfo"
          />
        </div>
      </div>
      <div class="col-12 col-md-6" v-else>
        <h2>Tasks: {{ ladderInfo.total_tasks }}</h2>
        <button
          class="btn btn-sm btn-success w-100"
          type="button"
          @click.stop="joinLadder"
        >
          Join
        </button>
      </div>
      <div class="col-12 col-md-6" v-if="subscribers.length > 0">
        <h2>Ranking</h2>
        <table class="table mt-3">
          <thead>
            <tr>
              <th>Rank</th>
              <th>Username</th>
              <th>Solved</th>
              <th v-if="ladderInfo.challenge_ladder">Score</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(subscriber, idx) in subscribers"
              :key="subscriber.username"
            >
              <td>{{ idx + 1 }}</td>
              <td>{{ subscriber.username }}</td>
              <td>{{ subscriber.solved }}</td>
              <td v-if="ladderInfo.challenge_ladder">{{ subscriber.score }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI, postAPI } from "../fetch-utils";
import TaskEntry from "../components/task-entry.vue";

export default {
  components: { TaskEntry },
  data() {
    return {
      infoLoaded: false,
      ladderInfo: {},
      tasks: [],
      taskCount: 0,
      subscribers: [],
      errmsg: "",
    };
  },
  created() {
    this.loadLadderInfo();
  },
  methods: {
    loadLadderInfo() {
      fetchAPI(`/api/ladder/info/${this.$route.params.slug}`)
        .then((res) => {
          this.ladderInfo = res;
          this.loadTasks();
          this.loadSubscribers();
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    loadTasks() {
      fetchAPI(`/api/ladder/tasks/${this.ladderInfo.id}`)
        .then((res) => {
          this.tasks = res;
          this.tasks.reverse();
          this.infoLoaded = true;
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    loadSubscribers() {
      fetchAPI(`/api/ladder/subscribers/${this.ladderInfo.id}`)
        .then((res) => {
          this.subscribers = res;
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    joinLadder() {
      postAPI(`/api/ladder/join/${this.ladderInfo.id}`, {})
        .then((res) => {
          this.loadLadderInfo();
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    leaveLadder() {
      postAPI(`/api/ladder/leave/${this.ladderInfo.id}`, {})
        .then((res) => {
          this.loadLadderInfo();
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
  },
};
</script>
