<template>
  <div>
    <h1>Ranking</h1>
    <div class="row">
      <div class="col-12 col-lg-6">
        <table class="table mt-3">
          <thead>
            <tr>
              <th>Username</th>
              <th class="cursor-pointer" @click="sortBySolved">Solved</th>
              <th class="cursor-pointer" @click="sortByScore">Score</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="rank in ranking" :key="rank.username">
              <td>{{ rank.username }}</td>
              <td>{{ rank.solved }}</td>
              <td>{{ rank.score }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI } from "../fetch-utils";

export default {
  data() {
    return {
      ranking: [],
    };
  },
  created() {
    this.loadRanking();
  },
  methods: {
    loadRanking() {
      fetchAPI("/api/ladder/ranking")
        .then((res) => {
          this.ranking = res;
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
    sortBySolved() {
      this.ranking.sort((a, b) => b.solved - a.solved);
    },
    sortByScore() {
      this.ranking.sort((a, b) => b.score - a.score);
    },
  },
};
</script>
