<template>
  <div
    class="col-lg-5 mr-3 shadow-sm p-2 mt-3 border"
    :class="[reward.price <= available ? 'border-success' : 'border-danger']"
  >
    <div v-if="err" class="text-danger">{{ err }}</div>
    <div v-if="editing">
      <form @submit.prevent="saveReward">
        <div class="form-group">
          <label :for="'new-reward-name-' + reward.id">Name</label>
          <input
            class="form-control"
            :id="'new-reward-name-' + reward.id"
            type="text"
            placeholder="Name"
            required
            v-model="reward.name"
          />
        </div>
        <div class="form-group">
          <label :for="'new-reward-description-' + reward.id">
            Description
          </label>
          <textarea
            class="form-control"
            :id="'new-reward-description-' + reward.id"
            placeholder="Description"
            rows="3"
            required
            v-model="reward.description"
          />
        </div>
        <div class="form-group">
          <label :for="'new-reward-link-' + reward.id">Link</label>
          <input
            class="form-control"
            :id="'new-reward-link-' + reward.id"
            type="text"
            placeholder="Link"
            v-model="reward.link"
          />
        </div>
        <div class="form-group">
          <label :for="'new-reward-price-' + reward.id">Price</label>
          <input
            class="form-control"
            :id="'new-reward-price-' + reward.id"
            type="number"
            min="1"
            placeholder="Price"
            required
            v-model="reward.price"
          />
        </div>
        <button class="btn btn-primary" type="submit">Save Reward</button>
      </form>
    </div>
    <div v-else>
      <div class="d-flex justify-content-between">
        <div>
          <h4>{{ reward.name }}</h4>
        </div>
        <div>
          <h4>{{ reward.price }}</h4>
        </div>
      </div>
      <div class="text-muted">
        <p v-for="line in reward.description.split(/\r?\n\r?\n/)" class="mb-2">
          {{ line }}
        </p>
      </div>
      <div v-if="reward.link">
        <a target="_blank" :href="reward.link">Link</a>
      </div>
      <div class="mt-2">
        <div v-if="reward.received > 0" class="text-muted mb-2">
          You claimed this reward {{ reward.received }} time{{
            reward.received > 1 ? "s" : ""
          }}.
        </div>
        <button
          v-if="reward.price <= available"
          class="btn btn-sm btn-primary"
          @click="claimReward"
        >
          Claim Reward
        </button>
        <button
          v-if="admin"
          class="btn btn-sm btn-outline-primary"
          @click="editing = true"
        >
          Edit
        </button>
        <button
          v-if="admin"
          class="btn btn-sm btn-outline-danger"
          @click="removeReward"
        >
          Remove
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { postAPI } from "../fetch-utils";

export default {
  props: {
    reward: Object,
    available: Number,
    admin: Boolean,
  },
  data() {
    return {
      editing: false,
      err: "",
    };
  },
  methods: {
    claimReward() {
      postAPI("/api/reward/claim/" + this.reward.id, {})
        .then((res) => {
          this.err = "";
          this.reward.received++;
          this.$emit("claimed");
        })
        .catch((err) => {
          this.err = err;
        });
    },
    saveReward() {
      postAPI("/api/reward/set/" + this.reward.id, {
        name: this.reward.name,
        description: this.reward.description,
        link: this.reward.link,
        price: parseInt(this.reward.price, 10),
      })
        .then((res) => {
          this.err = "";
          this.editing = false;
        })
        .catch((err) => {
          this.err = err;
        });
    },
    removeReward() {
      if (confirm("Do you really want to delete this reward?")) {
        postAPI("/api/reward/remove/" + this.reward.id, {})
          .then((res) => {
            this.$emit("changed");
          })
          .catch((err) => {
            this.err = err;
          });
      }
    },
  },
};
</script>
