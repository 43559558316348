<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-dark header-bg">
    <div class="container">
      <router-link to="/" class="navbar-brand">SOI Ladders</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <my-header-link to="/">Ladders</my-header-link>
          <my-header-link to="/ranking">Ranking</my-header-link>
          <my-header-link to="/rewards">Rewards</my-header-link>
          <my-header-link v-if="admin" to="/user_list">
            All Users
          </my-header-link>
          <my-header-link to="/settings">Settings</my-header-link>
        </ul>
        <div class="navbar-text mr-3">
          Hi {{ username }} (<span
            class="cursor-pointer"
            @click="$emit('logout')"
            >Logout</span
          >)
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
@import "../variables.scss";

.header-bg {
  background-color: $soi-blue !important;
}
</style>

<script>
import MyHeaderLink from "./my-header-link.vue";
export default {
  components: { MyHeaderLink },
  props: {
    username: String,
    admin: Boolean,
  },
};
</script>
