var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navbar navbar-expand-md navbar-dark bg-dark header-bg" },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "router-link",
            { staticClass: "navbar-brand", attrs: { to: "/" } },
            [_vm._v("SOI Ladders")]
          ),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "collapse navbar-collapse",
              attrs: { id: "navbarSupportedContent" }
            },
            [
              _c(
                "ul",
                { staticClass: "navbar-nav mr-auto" },
                [
                  _c("my-header-link", { attrs: { to: "/" } }, [
                    _vm._v("Ladders")
                  ]),
                  _vm._v(" "),
                  _c("my-header-link", { attrs: { to: "/ranking" } }, [
                    _vm._v("Ranking")
                  ]),
                  _vm._v(" "),
                  _c("my-header-link", { attrs: { to: "/rewards" } }, [
                    _vm._v("Rewards")
                  ]),
                  _vm._v(" "),
                  _vm.admin
                    ? _c("my-header-link", { attrs: { to: "/user_list" } }, [
                        _vm._v("\n          All Users\n        ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("my-header-link", { attrs: { to: "/settings" } }, [
                    _vm._v("Settings")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "navbar-text mr-3" }, [
                _vm._v("\n        Hi " + _vm._s(_vm.username) + " ("),
                _c(
                  "span",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function($event) {
                        return _vm.$emit("logout")
                      }
                    }
                  },
                  [_vm._v("Logout")]
                ),
                _vm._v(")\n      ")
              ])
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "navbar-toggler",
        attrs: {
          type: "button",
          "data-toggle": "collapse",
          "data-target": "#navbarSupportedContent",
          "aria-controls": "navbarSupportedContent",
          "aria-expanded": "false",
          "aria-label": "Toggle navigation"
        }
      },
      [_c("span", { staticClass: "navbar-toggler-icon" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }