<template>
  <div class="row my-3">
    <div class="col-8 cursor-pointer" @click="openTask">
      <div class="font-weight-bold">
        <a
          :href="task.url"
          @click.prevent
          class="text-decoration-none"
          :class="[task.solved ? 'text-success' : 'text-danger']"
        >
          {{ task.name }}
        </a>
      </div>
      <div v-if="challenge_ladder" class="text-muted">
        Score: {{ task.score }}
      </div>
      <div v-if="errmsg" class="text-danger">
        {{ errmsg }}
      </div>
    </div>
    <div class="col-4">
      <button
        v-if="!task.solved"
        class="btn btn-sm btn-outline-success w-100"
        @click.stop="checkSolved"
      >
        Check
      </button>
      <div v-else class="text-success text-center">
        Solved!
      </div>
    </div>
  </div>
</template>

<script>
import { postAPI } from "../fetch-utils";

export default {
  props: {
    task: Object,
    challenge_ladder: Boolean,
  },
  data() {
    return {
      errmsg: "",
    };
  },
  methods: {
    checkSolved() {
      postAPI(`/api/ladder/check/${this.task.id}`, {})
        .then((res) => {
          if (res) {
            this.$emit("solved");
            this.errmsg = "";
          } else {
            this.errmsg = "Task was not solved!";
          }
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    openTask() {
      window.open(this.task.url, "_blank");
    },
  },
};
</script>
