var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mt-3 border p-3 shadow-sm cursor-pointer",
      class: [_vm.ladder.subscribed ? "border-success" : "border-dark"],
      on: { click: _vm.openLadder }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-10" }, [
          _c(
            "h4",
            { class: [_vm.ladder.subscribed ? "text-body" : "text-muted"] },
            [
              _c(
                "router-link",
                {
                  staticClass: "no-link",
                  attrs: { to: "/ladder/" + _vm.ladder.slug }
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.ladder.name) + "\n        "
                  )
                ]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-2 my-2 my-sm-0" }, [
          _vm.ladder.subscribed
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-outline-dark w-100",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.leaveLadder($event)
                    }
                  }
                },
                [_vm._v("\n        Leave\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.ladder.subscribed
            ? _c(
                "button",
                {
                  staticClass: "btn btn-sm btn-success w-100",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.joinLadder($event)
                    }
                  }
                },
                [_vm._v("\n        Join\n      ")]
              )
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-muted" },
        _vm._l(_vm.ladder.description.split(/\r?\n\r?\n/), function(line) {
          return _c("p", { staticClass: "mb-2" }, [
            _vm._v("\n      " + _vm._s(line) + "\n    ")
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.errmsg
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v(_vm._s(_vm.errmsg))
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }