var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Settings")]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-2" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("h2", [_vm._v("Handles")]),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.saveHandles($event)
              }
            }
          },
          [
            _vm.errhandle
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errhandle))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "settings-codeforces" } }, [
                _vm._v("Codeforces")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.handles.codeforces,
                    expression: "handles.codeforces"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "settings-codeforces",
                  type: "text",
                  placeholder: "Codeforces"
                },
                domProps: { value: _vm.handles.codeforces },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.handles, "codeforces", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v(
                  "\n            Within one minute before saving, submit a Compiler Error using the\n            language "
                ),
                _c("i", [_vm._v(_vm._s(_vm.handles.codeforces_verify))]),
                _vm._v(".\n          ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "settings-spoj" } }, [
                _vm._v("SPOJ")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.handles.spoj,
                    expression: "handles.spoj"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "settings-spoj",
                  type: "text",
                  placeholder: "SPOJ"
                },
                domProps: { value: _vm.handles.spoj },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.handles, "spoj", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("small", { staticClass: "form-text text-muted" }, [
                _vm._v("\n            Include "),
                _c("i", [_vm._v(_vm._s(_vm.handles.spoj_verify))]),
                _vm._v(
                  " in your bio (you can\n            remove it again afterwards).\n          "
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mb-3",
                attrs: { type: "submit", disabled: !_vm.handlesChanged }
              },
              [_vm._v("\n          Save\n        ")]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("h2", [_vm._v("General")]),
        _vm._v(" "),
        _vm.errsettings
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.errsettings))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.saveSettings($event)
              }
            }
          },
          [
            _c("div", { staticClass: "form-group form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.settings.public_ranking,
                    expression: "settings.public_ranking"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { id: "settings-public-ranking", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.settings.public_ranking)
                    ? _vm._i(_vm.settings.public_ranking, null) > -1
                    : _vm.settings.public_ranking
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.settings.public_ranking,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.settings,
                            "public_ranking",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.settings,
                            "public_ranking",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.settings, "public_ranking", $$c)
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "settings-public-ranking" }
                },
                [_vm._v("\n            Show in public ranking\n          ")]
              )
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mb-3",
                attrs: { disabled: !_vm.settingsChanged, type: "submit" }
              },
              [_vm._v("\n          Save\n        ")]
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("h1", [_vm._v("My Ladders")]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-md-6 list-group" },
        _vm._l(_vm.myLadders, function(ladder) {
          return _c(
            "router-link",
            {
              key: ladder.id,
              staticClass: "list-group-item list-group-item-action",
              attrs: { to: "/ladder/" + ladder.slug + "/edit" }
            },
            [_vm._v("\n        " + _vm._s(ladder.name) + "\n      ")]
          )
        }),
        1
      )
    ]),
    _vm._v(" "),
    _c("h1", [_vm._v("New Ladder")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.addLadder($event)
              }
            }
          },
          [
            _vm.errladder
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errladder))
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "new-ladder-slug" } }, [
                _vm._v("Slug")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newSlug,
                    expression: "newSlug"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "new-ladder-slug",
                  type: "text",
                  placeholder: "Slug",
                  required: ""
                },
                domProps: { value: _vm.newSlug },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newSlug = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "new-ladder-name" } }, [
                _vm._v("Name")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newName,
                    expression: "newName"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "new-ladder-name",
                  type: "text",
                  placeholder: "Name",
                  required: ""
                },
                domProps: { value: _vm.newName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newName = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "new-ladder-description" } }, [
                _vm._v("Description")
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newDescription,
                    expression: "newDescription"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "new-ladder-description",
                  placeholder: "Description",
                  rows: "3",
                  required: ""
                },
                domProps: { value: _vm.newDescription },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newDescription = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "new-ladder-visible" } }, [
                _vm._v("Number of visible tasks")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newVisibleTasks,
                    expression: "newVisibleTasks"
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "new-ladder-visible", type: "number", min: "1" },
                domProps: { value: _vm.newVisibleTasks },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newVisibleTasks = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary mb-3",
                attrs: { type: "submit" }
              },
              [_vm._v("Add")]
            )
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }