var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Ranking")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 col-lg-8" },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.users, function(user) {
            return _c("div", { key: user.username }, [
              _c(
                "div",
                {
                  staticClass: "row cursor-pointer",
                  attrs: {
                    "data-toggle": "collapse",
                    "data-target": "#ladderinfo" + user.username,
                    "aria-expanded": "false",
                    "aria-controls": "ladderinfo" + user.username
                  }
                },
                [
                  _c("div", { staticClass: "col-3" }, [
                    _vm._v(
                      "\n            " + _vm._s(user.username) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://codeforces.com/profile/" + user.codeforces,
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(user.codeforces) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.spoj.com/users/" + user.spoj + "/",
                          target: "_blank"
                        }
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(user.spoj) +
                            "\n            "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _vm._v(
                      "\n            " + _vm._s(user.solved) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-2" }, [
                    _vm._v(
                      "\n            " + _vm._s(user.score) + "\n          "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "collapse",
                  attrs: { id: "ladderinfo" + user.username }
                },
                _vm._l(user.ladders, function(ladder) {
                  return _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-7" },
                      [
                        _c(
                          "router-link",
                          { attrs: { to: "/ladder/" + ladder.slug } },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(ladder.name) +
                                "\n              "
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-2" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(ladder.solved) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-2" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(ladder.score) +
                          "\n            "
                      )
                    ])
                  ])
                }),
                0
              )
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row font-weight-bold" }, [
      _c("div", { staticClass: "col-3" }, [
        _vm._v("\n          Username\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _vm._v("\n          Codeforces\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _vm._v("\n          Spoj\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _vm._v("\n          Solved\n        ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-2" }, [
        _vm._v("\n          Score\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }