var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mb-2" }, [
    _c("div", { staticClass: "d-flex" }, [
      _c("div", { staticClass: "mr-3" }, [
        _c("div", { staticClass: "form-group mb-1" }, [
          _c(
            "label",
            {
              staticClass: "sr-only",
              attrs: { for: "task-name-" + _vm.value.id }
            },
            [_vm._v("\n          Name\n        ")]
          ),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              id: "task-name-" + _vm.value.id,
              type: "text",
              placeholder: "Name"
            },
            domProps: { value: _vm.value.name },
            on: {
              input: function($event) {
                return _vm.updateValue($event.target.value, "name")
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mr-3 flex-grow-1" }, [
        _c("div", { staticClass: "form-group mb-1" }, [
          _c(
            "label",
            {
              staticClass: "sr-only",
              attrs: { for: "task-url-" + _vm.value.id }
            },
            [_vm._v("\n          URL\n        ")]
          ),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            attrs: {
              id: "task-url-" + _vm.value.id,
              type: "text",
              placeholder: "URL"
            },
            domProps: { value: _vm.value.url },
            on: {
              input: function($event) {
                return _vm.updateValue($event.target.value, "url")
              }
            }
          })
        ])
      ]),
      _vm._v(" "),
      _vm.challenge_ladder
        ? _c(
            "div",
            { staticClass: "mr-3", staticStyle: { "max-width": "5em" } },
            [
              _c("div", { staticClass: "form-group mb-1" }, [
                _c(
                  "label",
                  {
                    staticClass: "sr-only",
                    attrs: { for: "task-score-" + _vm.value.id }
                  },
                  [_vm._v("\n          Score\n        ")]
                ),
                _vm._v(" "),
                _c("input", {
                  staticClass: "form-control",
                  attrs: {
                    id: "task-score-" + _vm.value.id,
                    type: "number",
                    min: "0"
                  },
                  domProps: { value: _vm.value.score },
                  on: {
                    input: function($event) {
                      return _vm.updateValue($event.target.value, "score")
                    }
                  }
                })
              ])
            ]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.value.error
      ? _c("div", { staticClass: "text-danger mb-3" }, [
          _vm._v("\n    Could not save task. Please check the URL.\n  ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }