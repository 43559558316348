var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-3" }, [
      _c(
        "div",
        { staticClass: "col-lg-6" },
        [
          _c("h1", [_vm._v("Challenges")]),
          _vm._v(" "),
          _vm._l(_vm.challenges, function(ladder) {
            return _c(
              "div",
              { key: ladder.id },
              [_c("ladder-entry", { attrs: { ladder: ladder } })],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-lg-6 mt-3 mt-lg-0" },
        [
          _c("h1", [_vm._v("Topics")]),
          _vm._v(" "),
          _vm._l(_vm.topics, function(ladder) {
            return _c(
              "div",
              { key: ladder.id },
              [_c("ladder-entry", { attrs: { ladder: ladder } })],
              1
            )
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-4" }, [
      _c(
        "div",
        { staticClass: "col-lg-6" },
        [
          _c("h1", [_vm._v("User Ladders")]),
          _vm._v(" "),
          _vm._l(_vm.userGenerated, function(ladder) {
            return _c(
              "div",
              { key: ladder.id },
              [_c("ladder-entry", { attrs: { ladder: ladder } })],
              1
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }