import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global-style.scss";

import Vue from "vue";
import VueRouter from "vue-router";
import home from "./pages/home.vue";
import ladder from "./pages/ladder.vue";
import editladder from "./pages/editladder.vue";
import settings from "./pages/settings.vue";
import ranking from "./pages/ranking.vue";
import root from "./pages/root.vue";
import user_list from "./pages/user_list.vue";
import rewards from "./pages/rewards.vue";

const root_element = document.createElement("div");
root_element.id = "app";
document.body.appendChild(root_element);

const routes = [
  { path: "", component: home },
  { path: "/ladder/:slug", component: ladder },
  { path: "/ladder/:slug/edit", component: editladder },
  { path: "/ranking", component: ranking },
  { path: "/rewards", component: rewards },
  { path: "/user_list", component: user_list },
  { path: "/settings", component: settings },
];

const router = new VueRouter({
  routes,
});

Vue.use(VueRouter);

const vm = new Vue({
  router,
  render: (h) => h(root),
  el: "#app",
});
