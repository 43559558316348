<template>
  <div>
    <h1>{{ ladderInfo.name }}</h1>
    <div>
      <router-link :to="`/ladder/${this.ladderInfo.slug}`">
        View Ladder
      </router-link>
    </div>
    <div class="row my-3">
      <div class="col-md-6">
        <h2>Settings</h2>
        <div class="text-danger" v-if="errsettings">{{ errsettings }}</div>
        <form @submit.prevent="saveMeta">
          <div class="form-group">
            <label for="ladder-slug">Slug</label>
            <input
              class="form-control"
              id="ladder-slug"
              type="text"
              placeholder="Slug"
              v-model="ladderInfo.slug"
              required
            />
          </div>
          <div class="form-group">
            <label for="ladder-name">Name</label>
            <input
              class="form-control"
              id="ladder-name"
              type="text"
              placeholder="Name"
              v-model="ladderInfo.name"
              required
            />
          </div>
          <div class="form-group">
            <label for="ladder-description">Description</label>
            <textarea
              class="form-control"
              id="ladder-description"
              rows="3"
              placeholder="Description"
              v-model="ladderInfo.description"
              required
            />
          </div>
          <div class="form-group">
            <label for="ladder-visible">Number of visible tasks</label>
            <input
              class="form-control"
              id="ladder-visible"
              type="number"
              min="1"
              v-model="ladderInfo.visible_tasks"
            />
          </div>
          <template v-if="ladderInfo.can_fully_edit">
            <div class="form-group">
              <label for="ladder-category">Category</label>
              <select
                class="form-control"
                id="ladder-category"
                v-model="ladderInfo.category"
              >
                <option value="1">Challenges</option>
                <option value="2">Topics</option>
                <option value="0">User Ladders</option>
              </select>
            </div>
            <div class="form-group form-check">
              <input
                class="form-check-input"
                id="ladder-challenge"
                type="checkbox"
                v-model="ladderInfo.challenge_ladder"
              />
              <label for="ladder-challenge" class="form-check-label">
                Has scores
              </label>
            </div>
          </template>
          <button
            class="btn btn-primary"
            type="submit"
            :disabled="!settingsChanged"
          >
            Save
          </button>
          <button class="btn btn-danger" type="button" @click.prevent="remove">
            Delete Ladder
          </button>
        </form>
      </div>
    </div>
    <div class="my-2">
      <h2>Tasks</h2>
      <template v-if="shouldLoadTasks || ladderInfo.total_tasks === 0">
        <div
          class="d-flex mb-3 align-items-center"
          v-if="ladderInfo.challenge_ladder"
        >
          <div class="mr-3">
            <label class="mb-0" for="ladder-set-score">Score per Task</label>
          </div>
          <div class="mr-3">
            <input
              class="form-control"
              id="ladder-set-score"
              type="number"
              min="0"
              v-model="scorePerTask"
            />
          </div>
          <div class="mr-3">
            <button
              class="btn btn-secondary w-100"
              type="button"
              @click="applyScorePerTask"
            >
              Apply
            </button>
          </div>
        </div>
        <div v-for="(task, idx) in tasks" :key="task.lt_id">
          <task-entry-edit
            :challenge_ladder="ladderInfo.challenge_ladder"
            v-model="tasks[idx]"
          />
        </div>
        <div class="text-danger my-2" v-if="errtasks">{{ errtasks }}</div>
        <div
          class="text-success my-2"
          v-if="succtasks_failed + succtasks_success > 0"
        >
          Saved {{ succtasks_success }} tasks, {{ succtasks_failed }} failed.
        </div>
        <div>
          <button type="button" class="btn btn-secondary" @click="addTask">
            Add Task
          </button>
          <button type="button" class="btn btn-primary" @click="saveTasks">
            Save Tasks
          </button>
        </div>
      </template>
      <div v-else>
        <button
          type="button"
          class="btn btn-secondary"
          @click="setShouldLoadTasks"
        >
          Load Tasks
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI, postAPI } from "../fetch-utils";
import TaskEntry from "../components/task-entry.vue";
import TaskEntryEdit from "../components/task-entry-edit.vue";
import { isEqual } from "lodash/lang";

export default {
  components: { TaskEntryEdit, TaskEntry },
  data() {
    return {
      ladderInfo: {},
      origLadderInfo: {},
      shouldLoadTasks: false,
      tasks: [],
      scorePerTask: 0,
      errsettings: "",
      errtasks: "",
      succtasks_success: 0,
      succtasks_failed: 0,
    };
  },
  created() {
    this.loadLadderInfo();
  },
  computed: {
    settingsChanged() {
      return !isEqual(this.ladderInfo, this.origLadderInfo);
    },
  },
  methods: {
    loadLadderInfo() {
      fetchAPI(`/api/ladder/info/${this.$route.params.slug}`)
        .then((res) => {
          this.ladderInfo = res;
          this.origLadderInfo = { ...res };
          if (!this.ladderInfo.can_edit) {
            this.$router.replace(`/ladder/${this.ladderInfo.slug}`);
          }
          this.errsettings = "";
          if (this.shouldLoadTasks) {
            this.loadTasks();
          }
        })
        .catch((err) => {
          this.errsettings = err;
        });
    },
    setShouldLoadTasks() {
      this.shouldLoadTasks = true;
      this.loadTasks();
    },
    loadTasks() {
      fetchAPI(`/api/ladder/tasks/${this.ladderInfo.id}?all=true`)
        .then((res) => {
          this.tasks = res;
          this.errtasks = "";
          if (this.tasks.length > 0) {
            this.scorePerTask = this.tasks[0].score;
          }
        })
        .catch((err) => {
          this.errtasks = err;
        });
    },
    remove() {
      if (
        confirm(
          "Do you really want to delete this ladder?\n\nThis can not be undone!"
        )
      ) {
        postAPI(`/api/ladder/remove/${this.ladderInfo.id}`)
          .then((res) => {
            this.$router.replace("/");
          })
          .catch((err) => {
            this.errsettings = err;
          });
      }
    },
    saveMeta() {
      postAPI(`/api/ladder/set_info/${this.ladderInfo.id}`, {
        slug: this.ladderInfo.slug,
        name: this.ladderInfo.name,
        description: this.ladderInfo.description,
        visible_tasks: parseInt(this.ladderInfo.visible_tasks, 10),
        category: parseInt(this.ladderInfo.category, 10),
        challenge_ladder: this.ladderInfo.challenge_ladder,
      })
        .then((res) => {
          if (this.ladderInfo.slug !== this.origLadderInfo.slug) {
            this.$router.replace(`/ladder/${this.ladderInfo.slug}/edit`);
          }
          this.origLadderInfo = { ...this.ladderInfo };
          this.errsettings = "";
        })
        .catch((err) => {
          this.errsettings = err;
        });
    },
    addTask() {
      this.tasks.push({
        id: this.tasks.length * 1000,
        name: "",
        url: "",
        score: 0,
      });
    },
    applyScorePerTask() {
      this.tasks.forEach((task) => {
        task.score = this.scorePerTask;
      });
    },
    saveTasks() {
      const tasks = this.tasks.map((task, idx) => ({
        order: idx + 1,
        name: task.name,
        url: task.url,
        score: parseInt(task.score, 10),
      }));
      const sliceSize = 142;
      this.succtasks_failed = 0;
      this.succtasks_success = 0;
      this.errtasks = "";
      let promises = [];
      for (let i = 0; i < tasks.length; i += sliceSize) {
        promises.push(
          postAPI(`/api/ladder/set_tasks/${this.ladderInfo.id}`, {
            tasks: tasks.slice(i, i + sliceSize),
          })
            .then((res) => {
              this.succtasks_success += res.success.length;
              this.succtasks_failed += res.failed.length;
              this.tasks = this.tasks.map((task) => {
                if (res.success.includes(task.url)) {
                  return {
                    ...task,
                    error: false,
                  };
                } else if (res.failed.includes(task.url)) {
                  return {
                    ...task,
                    error: true,
                  };
                }
                return task;
              });
            })
            .catch((err) => {
              this.errtasks += err;
            })
        );
      }
      Promise.all(promises).then(() => {
        postAPI(`/api/ladder/prune_tasks/${this.ladderInfo.id}`, {
          max_order: tasks.length + 1,
        })
          .then((res) => {})
          .catch((err) => {
            this.errtasks += err;
          });
      });
    },
  },
};
</script>
