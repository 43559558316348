<template>
  <div
    class="mt-3 border p-3 shadow-sm cursor-pointer"
    :class="[ladder.subscribed ? 'border-success' : 'border-dark']"
    @click="openLadder"
  >
    <div class="row">
      <div class="col-sm-10">
        <h4 :class="[ladder.subscribed ? 'text-body' : 'text-muted']">
          <router-link class="no-link" :to="`/ladder/${ladder.slug}`">
            {{ ladder.name }}
          </router-link>
        </h4>
      </div>
      <div class="col-sm-2 my-2 my-sm-0">
        <button
          v-if="ladder.subscribed"
          class="btn btn-sm btn-outline-dark w-100"
          type="button"
          @click.stop="leaveLadder"
        >
          Leave
        </button>
        <button
          v-if="!ladder.subscribed"
          class="btn btn-sm btn-success w-100"
          type="button"
          @click.stop="joinLadder"
        >
          Join
        </button>
      </div>
    </div>
    <div class="text-muted">
      <p v-for="line in ladder.description.split(/\r?\n\r?\n/)" class="mb-2">
        {{ line }}
      </p>
    </div>
    <div v-if="errmsg" class="text-danger">{{ errmsg }}</div>
  </div>
</template>

<script>
import { postAPI } from "../fetch-utils";

export default {
  props: {
    ladder: Object,
  },
  data() {
    return {
      errmsg: "",
    };
  },
  methods: {
    joinLadder() {
      postAPI(`/api/ladder/join/${this.ladder.id}`, {})
        .then((res) => {
          this.openLadder();
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    leaveLadder() {
      postAPI(`/api/ladder/leave/${this.ladder.id}`, {})
        .then((res) => {
          this.ladder.subscribed = false;
          this.errmsg = "";
        })
        .catch((err) => {
          this.errmsg = err;
        });
    },
    openLadder() {
      this.$router.push(`/ladder/${this.ladder.slug}`);
    },
  },
};
</script>
