var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Rewards")]),
    _vm._v(" "),
    _c("div", [_vm._v("Available Score: " + _vm._s(_vm.available))]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mb-3" },
      _vm._l(_vm.rewards, function(reward) {
        return _c("reward-entry", {
          key: reward.id,
          attrs: { reward: reward, available: _vm.available, admin: _vm.admin },
          on: { changed: _vm.loadRewards, claimed: _vm.loadAvailable }
        })
      }),
      1
    ),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.admin
      ? _c("div", [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-6 px-2" }, [
              _c("h1", [_vm._v("New Reward")]),
              _vm._v(" "),
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.addReward($event)
                    }
                  }
                },
                [
                  _vm.newRewardErr
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.newRewardErr) +
                            "\n          "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "new-reward-name" } }, [
                      _vm._v("Name")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newName,
                          expression: "newName"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "new-reward-name",
                        type: "text",
                        placeholder: "Name",
                        required: ""
                      },
                      domProps: { value: _vm.newName },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newName = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "new-reward-description" } }, [
                      _vm._v("Description")
                    ]),
                    _vm._v(" "),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newDescription,
                          expression: "newDescription"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "new-reward-description",
                        placeholder: "Description",
                        rows: "3",
                        required: ""
                      },
                      domProps: { value: _vm.newDescription },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newDescription = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "new-reward-link" } }, [
                      _vm._v("Link")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newLink,
                          expression: "newLink"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "new-reward-link",
                        type: "text",
                        placeholder: "Link"
                      },
                      domProps: { value: _vm.newLink },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newLink = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "new-reward-price" } }, [
                      _vm._v("Price")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newPrice,
                          expression: "newPrice"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "new-reward-price",
                        type: "number",
                        min: "1",
                        placeholder: "Price",
                        required: ""
                      },
                      domProps: { value: _vm.newPrice },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newPrice = $event.target.value
                        }
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary mb-3",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("\n            Add Reward\n          ")]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-md-6 px-2" },
              [
                _c("h1", [_vm._v("Claims")]),
                _vm._v(" "),
                _vm.claimErr
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.claimErr))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.claims, function(claim) {
                  return _c(
                    "div",
                    {
                      staticClass: "d-flex justify-content-between mb-2",
                      class: [claim.received ? "text-muted" : ""]
                    },
                    [
                      _c("div", [
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v(_vm._s(claim.username) + ":")
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(claim.count) +
                            "x\n            " +
                            _vm._s(claim.reward) +
                            "\n          "
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-sm btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.toggleReceived(claim)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  claim.received ? "Reset" : "Mark received"
                                ) +
                                "\n            "
                            )
                          ]
                        )
                      ])
                    ]
                  )
                })
              ],
              2
            )
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text-muted mb-3" }, [
      _c("small", [
        _vm._v(
          "Eligible for rewards are only participants of the Swiss Olympiad in\n      Informatics who can still qualify for the International Olympiad in\n      Informatics (this year or in a later year)."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }