var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Ranking")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-lg-6" }, [
        _c("table", { staticClass: "table mt-3" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("Username")]),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "cursor-pointer",
                  on: { click: _vm.sortBySolved }
                },
                [_vm._v("Solved")]
              ),
              _vm._v(" "),
              _c(
                "th",
                {
                  staticClass: "cursor-pointer",
                  on: { click: _vm.sortByScore }
                },
                [_vm._v("Score")]
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "tbody",
            _vm._l(_vm.ranking, function(rank) {
              return _c("tr", { key: rank.username }, [
                _c("td", [_vm._v(_vm._s(rank.username))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(rank.solved))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(rank.score))])
              ])
            }),
            0
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }