<template>
  <div class="container-md login-wrapper">
    <h1>SOI Ladders</h1>
    <form class="mt-4" method="post" action="/api/auth/login">
      <button class="btn btn-primary btn-lg" type="submit">
        Sign in with soi.ch
      </button>
    </form>
    <div v-if="showDebugLogin">
      <h2 class="mt-5">Debug Login</h2>
      <form
        class="form-inline mt-2 justify-content-center"
        method="post"
        action="/api/auth/debug_login"
      >
        <label class="sr-only" for="loginDebugUsername">Username</label>
        <input
          class="form-control mr-2"
          type="text"
          placeholder="username"
          name="username"
          id="loginDebugUsername"
        />
        <div class="form-check mr-2">
          <input
            class="form-check-input"
            type="checkbox"
            name="admin"
            id="loginDebugAdmin"
          />
          <label class="form-check-label" for="loginDebugAdmin">
            Admin
          </label>
        </div>
        <button class="btn btn-primary btn-lg" type="submit">Sign in</button>
      </form>
    </div>
  </div>
</template>

<style lang="scss">
.login-wrapper {
  margin-top: 200px;
  text-align: center;
}
</style>

<script>
import { isDebug } from "../../settings";

export default {
  data() {
    return {
      showDebugLogin: isDebug,
    };
  },
};
</script>
