var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.whoamiLoaded
    ? _c("div", [
        _vm.loggedIn
          ? _c(
              "div",
              [
                _c("my-header", {
                  staticClass: "mb-3",
                  attrs: { username: _vm.username, admin: _vm.admin },
                  on: { logout: _vm.logout }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "container" }, [_c("router-view")], 1)
              ],
              1
            )
          : _c("div", [_c("login")], 1)
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }