var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row my-3" }, [
    _c(
      "div",
      { staticClass: "col-8 cursor-pointer", on: { click: _vm.openTask } },
      [
        _c("div", { staticClass: "font-weight-bold" }, [
          _c(
            "a",
            {
              staticClass: "text-decoration-none",
              class: [_vm.task.solved ? "text-success" : "text-danger"],
              attrs: { href: _vm.task.url },
              on: {
                click: function($event) {
                  $event.preventDefault()
                }
              }
            },
            [_vm._v("\n        " + _vm._s(_vm.task.name) + "\n      ")]
          )
        ]),
        _vm._v(" "),
        _vm.challenge_ladder
          ? _c("div", { staticClass: "text-muted" }, [
              _vm._v("\n      Score: " + _vm._s(_vm.task.score) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.errmsg
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n      " + _vm._s(_vm.errmsg) + "\n    ")
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-4" }, [
      !_vm.task.solved
        ? _c(
            "button",
            {
              staticClass: "btn btn-sm btn-outline-success w-100",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.checkSolved($event)
                }
              }
            },
            [_vm._v("\n      Check\n    ")]
          )
        : _c("div", { staticClass: "text-success text-center" }, [
            _vm._v("\n      Solved!\n    ")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }