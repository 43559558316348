<template>
  <div v-if="whoamiLoaded">
    <div v-if="loggedIn">
      <my-header
        class="mb-3"
        :username="username"
        :admin="admin"
        @logout="logout"
      />
      <div class="container">
        <router-view />
      </div>
    </div>
    <div v-else>
      <login></login>
    </div>
  </div>
</template>

<script>
import { fetchAPI, postAPI } from "../fetch-utils";
import login from "./login.vue";
import myHeader from "../components/my-header.vue";

export default {
  components: {
    login,
    myHeader,
  },
  data() {
    return {
      whoamiLoaded: false,
      loggedIn: false,
      username: false,
      admin: false,
    };
  },
  created() {
    this.loadWhoAmI();
  },
  methods: {
    loadWhoAmI() {
      fetchAPI("/api/auth/whoami")
        .then((res) => {
          this.loggedIn = res.logged_in;
          this.username = res.username;
          this.admin = res.admin;
          this.whoamiLoaded = true;
        })
        .catch((err) => {
          // TODO handle error
        });
    },
    logout() {
      postAPI("/api/auth/logout").then((res) => {
        this.loadWhoAmI();
      });
    },
  },
};
</script>
