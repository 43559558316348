var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v(_vm._s(_vm.ladderInfo.name))]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "router-link",
          { attrs: { to: "/ladder/" + this.ladderInfo.slug } },
          [_vm._v("\n      View Ladder\n    ")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row my-3" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("h2", [_vm._v("Settings")]),
        _vm._v(" "),
        _vm.errsettings
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(_vm._s(_vm.errsettings))
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.saveMeta($event)
              }
            }
          },
          [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "ladder-slug" } }, [_vm._v("Slug")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ladderInfo.slug,
                    expression: "ladderInfo.slug"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "ladder-slug",
                  type: "text",
                  placeholder: "Slug",
                  required: ""
                },
                domProps: { value: _vm.ladderInfo.slug },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ladderInfo, "slug", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "ladder-name" } }, [_vm._v("Name")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ladderInfo.name,
                    expression: "ladderInfo.name"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "ladder-name",
                  type: "text",
                  placeholder: "Name",
                  required: ""
                },
                domProps: { value: _vm.ladderInfo.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ladderInfo, "name", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "ladder-description" } }, [
                _vm._v("Description")
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ladderInfo.description,
                    expression: "ladderInfo.description"
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  id: "ladder-description",
                  rows: "3",
                  placeholder: "Description",
                  required: ""
                },
                domProps: { value: _vm.ladderInfo.description },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.ladderInfo, "description", $event.target.value)
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "ladder-visible" } }, [
                _vm._v("Number of visible tasks")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.ladderInfo.visible_tasks,
                    expression: "ladderInfo.visible_tasks"
                  }
                ],
                staticClass: "form-control",
                attrs: { id: "ladder-visible", type: "number", min: "1" },
                domProps: { value: _vm.ladderInfo.visible_tasks },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.ladderInfo,
                      "visible_tasks",
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _vm._v(" "),
            _vm.ladderInfo.can_fully_edit
              ? [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "ladder-category" } }, [
                      _vm._v("Category")
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.ladderInfo.category,
                            expression: "ladderInfo.category"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { id: "ladder-category" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.ladderInfo,
                              "category",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "1" } }, [
                          _vm._v("Challenges")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "2" } }, [
                          _vm._v("Topics")
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "0" } }, [
                          _vm._v("User Ladders")
                        ])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-group form-check" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ladderInfo.challenge_ladder,
                          expression: "ladderInfo.challenge_ladder"
                        }
                      ],
                      staticClass: "form-check-input",
                      attrs: { id: "ladder-challenge", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.ladderInfo.challenge_ladder)
                          ? _vm._i(_vm.ladderInfo.challenge_ladder, null) > -1
                          : _vm.ladderInfo.challenge_ladder
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.ladderInfo.challenge_ladder,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.ladderInfo,
                                  "challenge_ladder",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.ladderInfo,
                                  "challenge_ladder",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.ladderInfo, "challenge_ladder", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "ladder-challenge" }
                      },
                      [_vm._v("\n              Has scores\n            ")]
                    )
                  ])
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "submit", disabled: !_vm.settingsChanged }
              },
              [_vm._v("\n          Save\n        ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-danger",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.remove($event)
                  }
                }
              },
              [_vm._v("\n          Delete Ladder\n        ")]
            )
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "my-2" },
      [
        _c("h2", [_vm._v("Tasks")]),
        _vm._v(" "),
        _vm.shouldLoadTasks || _vm.ladderInfo.total_tasks === 0
          ? [
              _vm.ladderInfo.challenge_ladder
                ? _c("div", { staticClass: "d-flex mb-3 align-items-center" }, [
                    _vm._m(0),
                    _vm._v(" "),
                    _c("div", { staticClass: "mr-3" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.scorePerTask,
                            expression: "scorePerTask"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "ladder-set-score",
                          type: "number",
                          min: "0"
                        },
                        domProps: { value: _vm.scorePerTask },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.scorePerTask = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mr-3" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary w-100",
                          attrs: { type: "button" },
                          on: { click: _vm.applyScorePerTask }
                        },
                        [_vm._v("\n            Apply\n          ")]
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.tasks, function(task, idx) {
                return _c(
                  "div",
                  { key: task.lt_id },
                  [
                    _c("task-entry-edit", {
                      attrs: {
                        challenge_ladder: _vm.ladderInfo.challenge_ladder
                      },
                      model: {
                        value: _vm.tasks[idx],
                        callback: function($$v) {
                          _vm.$set(_vm.tasks, idx, $$v)
                        },
                        expression: "tasks[idx]"
                      }
                    })
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.errtasks
                ? _c("div", { staticClass: "text-danger my-2" }, [
                    _vm._v(_vm._s(_vm.errtasks))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.succtasks_failed + _vm.succtasks_success > 0
                ? _c("div", { staticClass: "text-success my-2" }, [
                    _vm._v(
                      "\n        Saved " +
                        _vm._s(_vm.succtasks_success) +
                        " tasks, " +
                        _vm._s(_vm.succtasks_failed) +
                        " failed.\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: { click: _vm.addTask }
                  },
                  [_vm._v("\n          Add Task\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.saveTasks }
                  },
                  [_vm._v("\n          Save Tasks\n        ")]
                )
              ])
            ]
          : _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button" },
                  on: { click: _vm.setShouldLoadTasks }
                },
                [_vm._v("\n        Load Tasks\n      ")]
              )
            ])
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-3" }, [
      _c("label", { staticClass: "mb-0", attrs: { for: "ladder-set-score" } }, [
        _vm._v("Score per Task")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }