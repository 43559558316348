var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-md login-wrapper" }, [
    _c("h1", [_vm._v("SOI Ladders")]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _vm.showDebugLogin
      ? _c("div", [
          _c("h2", { staticClass: "mt-5" }, [_vm._v("Debug Login")]),
          _vm._v(" "),
          _vm._m(1)
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        staticClass: "mt-4",
        attrs: { method: "post", action: "/api/auth/login" }
      },
      [
        _c(
          "button",
          { staticClass: "btn btn-primary btn-lg", attrs: { type: "submit" } },
          [_vm._v("\n      Sign in with soi.ch\n    ")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "form",
      {
        staticClass: "form-inline mt-2 justify-content-center",
        attrs: { method: "post", action: "/api/auth/debug_login" }
      },
      [
        _c(
          "label",
          { staticClass: "sr-only", attrs: { for: "loginDebugUsername" } },
          [_vm._v("Username")]
        ),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control mr-2",
          attrs: {
            type: "text",
            placeholder: "username",
            name: "username",
            id: "loginDebugUsername"
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-check mr-2" }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: { type: "checkbox", name: "admin", id: "loginDebugAdmin" }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "loginDebugAdmin" }
            },
            [_vm._v("\n          Admin\n        ")]
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary btn-lg", attrs: { type: "submit" } },
          [_vm._v("Sign in")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }