<template>
  <div>
    <div class="row mb-3">
      <div class="col-lg-6">
        <h1>Challenges</h1>
        <div v-for="ladder in challenges" :key="ladder.id">
          <ladder-entry :ladder="ladder" />
        </div>
      </div>
      <div class="col-lg-6 mt-3 mt-lg-0">
        <h1>Topics</h1>
        <div v-for="ladder in topics" :key="ladder.id">
          <ladder-entry :ladder="ladder" />
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-6">
        <h1>User Ladders</h1>
        <div v-for="ladder in userGenerated" :key="ladder.id">
          <ladder-entry :ladder="ladder" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI } from "../fetch-utils";
import ladderEntry from "../components/ladder-entry.vue";

export default {
  components: {
    ladderEntry,
  },
  data() {
    return {
      ladders: [],
    };
  },
  created() {
    this.loadLadders();
  },
  computed: {
    challenges() {
      return this.laddersOfCategory(1);
    },
    topics() {
      return this.laddersOfCategory(2);
    },
    userGenerated() {
      return this.laddersOfCategory(0);
    },
  },
  methods: {
    loadLadders() {
      fetchAPI("/api/ladder/list")
        .then((res) => {
          this.ladders = res;
          this.ladders.sort((a, b) => {
            if (a.subscribed !== b.subscribed) {
              return a.subscribed ? -1 : 1;
            }
            if (a.name === b.name) {
              return 0;
            }
            return a.name < b.name ? -1 : 1;
          });
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
    laddersOfCategory(category) {
      return this.ladders.filter((x) => x.category === category);
    },
  },
};
</script>
