<template>
  <div>
    <h1>Rewards</h1>
    <div>Available Score: {{ available }}</div>
    <div class="row mb-3">
      <reward-entry
        v-for="reward in rewards"
        :key="reward.id"
        :reward="reward"
        :available="available"
        :admin="admin"
        @changed="loadRewards"
        @claimed="loadAvailable"
      />
    </div>
    <div class="text-muted mb-3">
      <small
        >Eligible for rewards are only participants of the Swiss Olympiad in
        Informatics who can still qualify for the International Olympiad in
        Informatics (this year or in a later year).</small
      >
    </div>
    <div v-if="admin">
      <div class="row">
        <div class="col-md-6 px-2">
          <h1>New Reward</h1>
          <form @submit.prevent="addReward">
            <div v-if="newRewardErr" class="text-danger">
              {{ newRewardErr }}
            </div>
            <div class="form-group">
              <label for="new-reward-name">Name</label>
              <input
                class="form-control"
                id="new-reward-name"
                type="text"
                placeholder="Name"
                required
                v-model="newName"
              />
            </div>
            <div class="form-group">
              <label for="new-reward-description">Description</label>
              <textarea
                class="form-control"
                id="new-reward-description"
                placeholder="Description"
                rows="3"
                required
                v-model="newDescription"
              />
            </div>
            <div class="form-group">
              <label for="new-reward-link">Link</label>
              <input
                class="form-control"
                id="new-reward-link"
                type="text"
                placeholder="Link"
                v-model="newLink"
              />
            </div>
            <div class="form-group">
              <label for="new-reward-price">Price</label>
              <input
                class="form-control"
                id="new-reward-price"
                type="number"
                min="1"
                placeholder="Price"
                required
                v-model="newPrice"
              />
            </div>
            <button class="btn btn-primary mb-3" type="submit">
              Add Reward
            </button>
          </form>
        </div>
        <div class="col-md-6 px-2">
          <h1>Claims</h1>
          <div v-if="claimErr" class="text-danger">{{ claimErr }}</div>
          <div
            v-for="claim in claims"
            :class="[claim.received ? 'text-muted' : '']"
            class="d-flex justify-content-between mb-2"
          >
            <div>
              <span class="font-weight-bold">{{ claim.username }}:</span>
              {{ claim.count }}x
              {{ claim.reward }}
            </div>
            <div>
              <button
                type="button"
                class="btn btn-sm btn-outline-primary"
                @click="toggleReceived(claim)"
              >
                {{ claim.received ? "Reset" : "Mark received" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI, postAPI } from "../fetch-utils";
import RewardEntry from "../components/reward-entry.vue";

export default {
  components: { RewardEntry },
  data() {
    return {
      available: 0,
      rewards: [],
      newName: "",
      newLink: "",
      newDescription: "",
      newPrice: 1,
      newRewardErr: "",
      claimErr: "",
      admin: false,
      claims: [],
    };
  },
  created() {
    this.loadWhoAmI();
    this.loadRewards();
    this.loadAvailable();
  },
  methods: {
    loadWhoAmI() {
      fetchAPI("/api/auth/whoami")
        .then((res) => {
          this.admin = res.admin;
          if (this.admin) {
            this.loadClaims();
          }
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
    loadRewards() {
      fetchAPI("/api/reward/list")
        .then((res) => {
          this.rewards = res;
          this.rewards.sort((a, b) => a.price - b.price);
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
    loadAvailable() {
      fetchAPI("/api/reward/myscore")
        .then((res) => {
          this.available = res.available;
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
    addReward() {
      postAPI("/api/reward/add", {
        name: this.newName,
        link: this.newLink,
        description: this.newDescription,
        price: parseInt(this.newPrice, 10),
      })
        .then((res) => {
          this.loadRewards();
        })
        .catch((err) => {
          this.newRewardErr = err;
        });
    },
    loadClaims() {
      fetchAPI("/api/reward/list_claims")
        .then((res) => {
          res.sort((a, b) => {
            if (a.received === b.received) {
              if (a.username === b.username) {
                return a.reward.localeCompare(b.reward);
              }
              return a.username.localeCompare(b.username);
            }
            return a.received ? 1 : -1;
          });
          this.claims = [];
          if (res.length === 0) {
            return;
          }
          function init_ael(idx) {
            return {
              username: res[idx].username,
              reward: res[idx].reward,
              received: res[idx].received,
              count: 1,
              ids: [res[idx].id],
            };
          }
          let ael = init_ael(0);
          for (let i = 1; i < res.length; i++) {
            if (
              res[i].username === ael.username &&
              res[i].reward === ael.reward &&
              res[i].received === ael.received
            ) {
              ael.count++;
              ael.ids.push(res[i].id);
            } else {
              this.claims.push(ael);
              ael = init_ael(i);
            }
          }
          this.claims.push(ael);
          console.log(this.claims);
        })
        .catch((err) => {
          this.$emit("fail", err);
        });
    },
    toggleReceived(claim) {
      if (
        !claim.received ||
        confirm("Do you really want to mark this item as not received?")
      ) {
        Promise.all(
          claim.ids.map((id) =>
            postAPI("/api/reward/set_claim_received/" + id, {
              received: !claim.received,
            })
          )
        )
          .then((res) => {
            claim.received = !claim.received;
            this.claimErr = "";
          })
          .catch((err) => {
            this.claimErr = err;
          });
      }
    },
  },
};
</script>
