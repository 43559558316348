<template>
  <router-link :to="to" v-slot="{ href, navigate, isExactActive }">
    <li class="nav-item" :class="[isExactActive && 'active']">
      <a class="nav-link" :href="href" @click="navigate">
        <slot></slot>
      </a>
    </li>
  </router-link>
</template>

<script>
export default {
  props: {
    to: String,
  },
};
</script>
