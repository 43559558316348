var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "col-lg-5 mr-3 shadow-sm p-2 mt-3 border",
      class: [
        _vm.reward.price <= _vm.available ? "border-success" : "border-danger"
      ]
    },
    [
      _vm.err
        ? _c("div", { staticClass: "text-danger" }, [_vm._v(_vm._s(_vm.err))])
        : _vm._e(),
      _vm._v(" "),
      _vm.editing
        ? _c("div", [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.saveReward($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { attrs: { for: "new-reward-name-" + _vm.reward.id } },
                    [_vm._v("Name")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reward.name,
                        expression: "reward.name"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "new-reward-name-" + _vm.reward.id,
                      type: "text",
                      placeholder: "Name",
                      required: ""
                    },
                    domProps: { value: _vm.reward.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.reward, "name", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      attrs: { for: "new-reward-description-" + _vm.reward.id }
                    },
                    [_vm._v("\n          Description\n        ")]
                  ),
                  _vm._v(" "),
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reward.description,
                        expression: "reward.description"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "new-reward-description-" + _vm.reward.id,
                      placeholder: "Description",
                      rows: "3",
                      required: ""
                    },
                    domProps: { value: _vm.reward.description },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.reward, "description", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { attrs: { for: "new-reward-link-" + _vm.reward.id } },
                    [_vm._v("Link")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reward.link,
                        expression: "reward.link"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "new-reward-link-" + _vm.reward.id,
                      type: "text",
                      placeholder: "Link"
                    },
                    domProps: { value: _vm.reward.link },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.reward, "link", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    { attrs: { for: "new-reward-price-" + _vm.reward.id } },
                    [_vm._v("Price")]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.reward.price,
                        expression: "reward.price"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      id: "new-reward-price-" + _vm.reward.id,
                      type: "number",
                      min: "1",
                      placeholder: "Price",
                      required: ""
                    },
                    domProps: { value: _vm.reward.price },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.reward, "price", $event.target.value)
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v("Save Reward")]
                )
              ]
            )
          ])
        : _c("div", [
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("div", [_c("h4", [_vm._v(_vm._s(_vm.reward.name))])]),
              _vm._v(" "),
              _c("div", [_c("h4", [_vm._v(_vm._s(_vm.reward.price))])])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-muted" },
              _vm._l(_vm.reward.description.split(/\r?\n\r?\n/), function(
                line
              ) {
                return _c("p", { staticClass: "mb-2" }, [
                  _vm._v("\n        " + _vm._s(line) + "\n      ")
                ])
              }),
              0
            ),
            _vm._v(" "),
            _vm.reward.link
              ? _c("div", [
                  _c(
                    "a",
                    { attrs: { target: "_blank", href: _vm.reward.link } },
                    [_vm._v("Link")]
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2" }, [
              _vm.reward.received > 0
                ? _c("div", { staticClass: "text-muted mb-2" }, [
                    _vm._v(
                      "\n        You claimed this reward " +
                        _vm._s(_vm.reward.received) +
                        " time" +
                        _vm._s(_vm.reward.received > 1 ? "s" : "") +
                        ".\n      "
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.reward.price <= _vm.available
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-primary",
                      on: { click: _vm.claimReward }
                    },
                    [_vm._v("\n        Claim Reward\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.admin
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-primary",
                      on: {
                        click: function($event) {
                          _vm.editing = true
                        }
                      }
                    },
                    [_vm._v("\n        Edit\n      ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.admin
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-sm btn-outline-danger",
                      on: { click: _vm.removeReward }
                    },
                    [_vm._v("\n        Remove\n      ")]
                  )
                : _vm._e()
            ])
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }