<template>
  <div>
    <h1>Settings</h1>
    <div class="row mt-2">
      <div class="col-md-6">
        <h2>Handles</h2>
        <form @submit.prevent="saveHandles">
          <div class="text-danger" v-if="errhandle">{{ errhandle }}</div>
          <div class="form-group">
            <label for="settings-codeforces">Codeforces</label>
            <input
              id="settings-codeforces"
              class="form-control"
              type="text"
              placeholder="Codeforces"
              v-model="handles.codeforces"
            />
            <small class="form-text text-muted">
              Within one minute before saving, submit a Compiler Error using the
              language <i>{{ handles.codeforces_verify }}</i
              >.
            </small>
          </div>
          <div class="form-group">
            <label for="settings-spoj">SPOJ</label>
            <input
              id="settings-spoj"
              class="form-control"
              type="text"
              placeholder="SPOJ"
              v-model="handles.spoj"
            />
            <small class="form-text text-muted">
              Include <i>{{ handles.spoj_verify }}</i> in your bio (you can
              remove it again afterwards).
            </small>
          </div>
          <button
            type="submit"
            class="btn btn-primary mb-3"
            :disabled="!handlesChanged"
          >
            Save
          </button>
        </form>
      </div>
      <div class="col-md-6">
        <h2>General</h2>
        <div class="text-danger" v-if="errsettings">{{ errsettings }}</div>
        <form @submit.prevent="saveSettings">
          <div class="form-group form-check">
            <input
              id="settings-public-ranking"
              type="checkbox"
              class="form-check-input"
              v-model="settings.public_ranking"
            />
            <label for="settings-public-ranking" class="form-check-label">
              Show in public ranking
            </label>
          </div>
          <button
            class="btn btn-primary mb-3"
            :disabled="!settingsChanged"
            type="submit"
          >
            Save
          </button>
        </form>
      </div>
    </div>
    <h1>My Ladders</h1>
    <div class="row mb-3">
      <div class="col-md-6 list-group">
        <router-link
          v-for="ladder in myLadders"
          :key="ladder.id"
          class="list-group-item list-group-item-action"
          :to="`/ladder/${ladder.slug}/edit`"
        >
          {{ ladder.name }}
        </router-link>
      </div>
    </div>
    <h1>New Ladder</h1>
    <div class="row">
      <div class="col-md-6">
        <form @submit.prevent="addLadder">
          <div class="text-danger" v-if="errladder">{{ errladder }}</div>
          <div class="form-group">
            <label for="new-ladder-slug">Slug</label>
            <input
              class="form-control"
              id="new-ladder-slug"
              type="text"
              placeholder="Slug"
              required
              v-model="newSlug"
            />
          </div>
          <div class="form-group">
            <label for="new-ladder-name">Name</label>
            <input
              class="form-control"
              id="new-ladder-name"
              type="text"
              placeholder="Name"
              required
              v-model="newName"
            />
          </div>
          <div class="form-group">
            <label for="new-ladder-description">Description</label>
            <textarea
              class="form-control"
              id="new-ladder-description"
              placeholder="Description"
              rows="3"
              required
              v-model="newDescription"
            ></textarea>
          </div>
          <div class="form-group">
            <label for="new-ladder-visible">Number of visible tasks</label>
            <input
              class="form-control"
              id="new-ladder-visible"
              type="number"
              min="1"
              v-model="newVisibleTasks"
            />
          </div>
          <button class="btn btn-primary mb-3" type="submit">Add</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAPI, postAPI } from "../fetch-utils";
import { isEqual } from "lodash/lang";

export default {
  data() {
    return {
      handles: {},
      origHandles: {},
      settings: {},
      origSettings: {},
      myLadders: [],
      newSlug: "",
      newName: "",
      newDescription: "",
      newVisibleTasks: 1,
      errhandle: "",
      errsettings: "",
      errladder: "",
    };
  },
  created() {
    this.loadHandles();
    this.loadSettings();
    this.loadMyLadders();
  },
  computed: {
    handlesChanged() {
      return !isEqual(this.handles, this.origHandles);
    },
    settingsChanged() {
      return !isEqual(this.settings, this.origSettings);
    },
  },
  methods: {
    loadHandles() {
      fetchAPI("/api/auth/handle")
        .then((res) => {
          this.handles = res;
          this.origHandles = { ...res };
          this.errhandle = "";
        })
        .catch((err) => {
          this.errhandle = err;
        });
    },
    saveHandles() {
      postAPI("/api/auth/set_handle", this.handles)
        .then((res) => {
          this.origHandles = { ...this.handles };
          this.errhandle = "";
        })
        .catch((err) => {
          this.errhandle = err;
        });
    },
    loadSettings() {
      fetchAPI("/api/auth/settings")
        .then((res) => {
          this.settings = res;
          this.origSettings = { ...res };
          this.errsettings = "";
        })
        .catch((err) => {
          this.errsettings = err;
        });
    },
    saveSettings() {
      postAPI("/api/auth/set_settings", this.settings)
        .then((res) => {
          this.origSettings = { ...this.settings };
          this.errsettings = "";
        })
        .catch((err) => {
          this.errsettings = err;
        });
    },
    loadMyLadders() {
      fetchAPI("/api/ladder/list_mine")
        .then((res) => {
          this.myLadders = res;
        })
        .catch((err) => {
          this.errsettings = err; // TODO handle this better (it's not related to settings)
        });
    },
    addLadder() {
      postAPI("/api/ladder/add", {
        slug: this.newSlug,
        name: this.newName,
        description: this.newDescription,
        visible_tasks: parseInt(this.newVisibleTasks, 10),
      })
        .then((res) => {
          this.$router.push(`/ladder/${this.newSlug}/edit`);
        })
        .catch((err) => {
          this.errladder = err;
        });
    },
  },
};
</script>
